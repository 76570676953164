import "./styles.scss";
import Glide from '@glidejs/glide'

var sliders = document.querySelectorAll('.glide');

const mediaQuery = window.matchMedia('(max-width: 768px)')

function handleTabletChange(e) {
  // Check if the media query is true
  if (e.matches) {
    // Then log the following message to the console
    for (var i = 0; i < sliders.length; i++) {
      var glide = new Glide(sliders[i], {
        gap: 0,
        rewind: false
      });
      glide.mount();
    }
  }
}

// Register event listener
mediaQuery.addListener(handleTabletChange)

// Initial check
handleTabletChange(mediaQuery)




window.onresize = function(event) {
  const mediaQueryD = window.matchMedia('(min-width: 769px)')
  function handleDesktopChange(e) {
    // Check if the media query is true
    if (e.matches) {
      location.reload();
    }
  }
  
  // Register event listener
  mediaQueryD.addListener(handleDesktopChange)
  
  // Initial check
  handleDesktopChange(mediaQueryD)
};



ScrollTrigger.matchMedia({
	
  // desktop
  "(min-width: 800px)": function() {

    // a timeline that isn't ScrollTrigger-related...

    const boxes = gsap.utils.toArray(".text-wrap.druga");

    boxes.forEach((box) => {
      gsap.to(box, {
        
        scrollTrigger: {
          trigger: box,
          start: "top 80%",
          toggleActions: "restart none none none",
        },
        duration: 1,
        keyframes: [
          { y: 10, duration: 0.5 },
          { y: -10, duration: 0.5 },
          { y: 10, duration: 0.5 },
          { y: 0, duration: 0.5 },
        ],
        repeat: 0,
        yoyo: true,
        ease: Quad.easeInOut,
      });
    });
    
    const levi = gsap.utils.toArray(".text-wrap.prva");
    levi.forEach((leva) => {
      gsap.to(leva, {
        scrollTrigger: {
          trigger: leva,
          start: "top 80%",
          toggleActions: "restart none none none",
        },
        duration: 1,
        keyframes: [
          { y: -10, duration: 0.5 },
          { y: 10, duration: 0.5 },
          { y: -10, duration: 0.5 },
          { y: 0, duration: 0.5 },
        ],
        repeat: 0,
        yoyo: true,
        ease: Quad.easeInOut,
      });
    });
    

    // THIS IS THE KEY! Return a function which will get called when the media query no longer matches so we can kill() the animation (or whatever)
    return function() {
      tl.kill(); 
      // other cleanup code can go here...
    };
  }

});




ScrollTrigger.matchMedia({
	
  // desktop
  "(min-width: 800px)": function() {

    const trigger = [...document.getElementsByClassName("text-wrap")];

    trigger.forEach.call(trigger, (el) => {
      el.addEventListener("click", (e) => {
        const text = el.getElementsByClassName("notranja-animacija")[0];
    
        TweenMax.to(text, 0.5, { rotation: 180, ease: Linear.easeNone });
      });
    });
    // THIS IS THE KEY! Return a function which will get called when the media query no longer matches so we can kill() the animation (or whatever)
    return function() {
      tl.kill(); 
      // other cleanup code can go here...
    };
  }

});